<template>
  <div>
    <!--    Ekle Modal-->
    <b-modal
      id="new_record_modal"
      ref="new_record_modal"
      centered

      no-close-on-backdrop
      content-class="shadow"
      :title="$t('core.model_title_add')"
      footer-class="d-none"
      size="lg"
      @hide="close_new_record_modal"
    >
      <div>
        <b-row>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Plan Başık"
              label-for="name"
            >
              <b-form-input
                v-model="title"
                placeholder="Plan Başık"
                name="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Plan Alt Başlık"
              label-for="name"
            >
              <b-form-input
                v-model="sub_title"
                placeholder="Plan Alt Başlık"
                name="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Aylık Fiyat"
              label-for="name"
            >
              <b-form-input
                v-model="monthly_price"
                placeholder="Aylık Fiyat"
                name="name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Yıllık Fiyat"
              label-for="name"
            >
              <b-form-input
                v-model="annual_price"
                placeholder="Yıllık Fiyat"
                name="name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Para Birimi"
              label-for="name"
            >
              <v-select
                id="i-plan"
                v-model="currency"
                :options="currency_options"
                label="title"
                :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="6"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Sıra No"
              label-for="name"
            >
              <b-form-input
                v-model="priority_no"
                placeholder="Sıra No"
                name="name"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Plan TAG"
              label-for="name"
            >
              <v-select
                  id="i-plan"
                  v-model="plan_tag"
                  :options="plan_tag_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
                label="Kare Kod Sayısı"
                label-for="name"
            >
              <b-form-input
                  v-model="qr_code_limit"
                  placeholder="Kare Kod Sayısı"
                  name="name"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="Kullanıcı Sayısı"
                label-for="name"
            >
              <b-form-input
                  v-model="total_user_limit"
                  placeholder="Kullanıcı Sayısı"
                  name="name"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="Grup Sayısı"
                label-for="name"
            >
              <b-form-input
                  v-model="total_group_limit"
                  placeholder="Grup Sayısı"
                  name="name"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="Toplam Boyut (Byte)"
                label-for="name"
            >
              <b-form-input
                  v-model="max_disk_quota"
                  placeholder="Toplam Boyut (Byte)"
                  name="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
                label="Anketden Sonra Yönlendirme"
                label-for="name"
            >
              <v-select
                  id="i-plan"
                  v-model="custom_return_url"
                  :options="custom_return_url_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="QR Kod Özel Logo"
                label-for="name"
            >
              <v-select
                  id="i-plan"
                  v-model="custom_logo"
                  :options="custom_logo_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="Organizasyon Sayısı"
                label-for="name"
            >
              <b-form-input
                  v-model="total_organization_limit"
                  placeholder="Organizasyon Sayısı"
                  name="name"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="Biolink Kullanımı"
                label-for="name"
            >
              <v-select
                  id="i-plan"
                  v-model="biolink_status"
                  :options="biolink_status_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Özellikler (Özellikleri ayırmak için '|' ifadesini kullanabilirsiniz.)"
              label-for="name"
            >
              <b-form-textarea
                v-model="features"
                placeholder="Özellikler (Özellikleri ayırmak için '|' ifadesini kullanabilirsiniz.)"
                name="name"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row class="justify-content-end">
          <b-button
            size="sm"
              variant="primary"
            class="mr-1"
            @click="new_record_add_button"
          >

            <span class="align-middle">{{ $t('core.btn_add') }}</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Ekle Modal-->

    <!--    Güncelle Modal-->
    <b-modal
      id="update_record_modal"
      ref="update_record_modal"
      centered

      no-close-on-backdrop
      content-class="shadow"
      title="Kayıt Düzenle"
      footer-class="d-none"
      size="lg"
      @hide="close_edit_record_modal"
    >
      <div>
        <b-row>
          <b-col
              cols="12"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Plan Başlık"
                label-for="name"
            >
              <b-form-input
                  v-model="edit_title"
                  placeholder="Plan Başlık"
                  name="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
              cols="12"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Plan Alt Başlık"
                label-for="name"
            >
              <b-form-input
                  v-model="edit_sub_title"
                  placeholder="Plan Alt Başlık"
                  name="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
              cols="4"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Aylık Fiyat"
                label-for="name"
            >
              <b-form-input
                  v-model="edit_monthly_price"
                  placeholder="Aylık Fiyat"
                  name="name"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="4"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Yıllık Fiyat"
                label-for="name"
            >
              <b-form-input
                  v-model="edit_annual_price"
                  placeholder="Yıllık Fiyat"
                  name="name"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="4"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Para Birimi"
                label-for="name"
            >
              <v-select
                  id="i-plan"
                  v-model="edit_currency"
                  :options="currency_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
              cols="6"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Sıra No"
                label-for="name"
            >
              <b-form-input
                  v-model="edit_priority_no"
                  placeholder="Sıra No"
                  name="name"
                  type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="6"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Plan TAG"
                label-for="name"
            >
              <v-select
                  id="i-plan"
                  v-model="edit_plan_tag"
                  :options="plan_tag_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
                label="Kare Kod Sayısı"
                label-for="name"
            >
              <b-form-input
                  v-model="edit_qr_code_limit"
                  placeholder="Kare Kod Sayısı"
                  name="name"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="Kullanıcı Sayısı"
                label-for="name"
            >
              <b-form-input
                  v-model="edit_total_user_limit"
                  placeholder="Kullanıcı Sayısı"
                  name="name"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="Grup Sayısı"
                label-for="name"
            >
              <b-form-input
                  v-model="edit_total_group_limit"
                  placeholder="Grup Sayısı"
                  name="name"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="Toplam Boyut (Byte)"
                label-for="name"
            >
              <b-form-input
                  v-model="edit_max_disk_quota"
                  placeholder="Toplam Boyut (Byte)"
                  name="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
                label="Anketden Sonra Yönlendirme"
                label-for="name"
            >
              <v-select
                  id="i-plan"
                  v-model="edit_custom_return_url"
                  :options="custom_return_url_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="QR Kod Özel Logo"
                label-for="name"
            >
              <v-select
                  id="i-plan"
                  v-model="edit_custom_logo"
                  :options="custom_logo_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="Organizasyon Sayısı"
                label-for="name"
            >
              <b-form-input
                  v-model="edit_total_organization_limit"
                  placeholder="Organizasyon Sayısı"
                  name="name"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="Biolink Kullanımı"
                label-for="name"
            >
              <v-select
                  id="i-plan"
                  v-model="edit_biolink_status"
                  :options="biolink_status_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
              cols="12"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Özellikler (Özellikleri ayırmak için '|' ifadesini kullanabilirsiniz.)"
                label-for="name"
            >
              <b-form-textarea
                  v-model="edit_features"
                  placeholder="Özellikler (Özellikleri ayırmak için '|' ifadesini kullanabilirsiniz.)"
                  name="name"
                  rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row class="justify-content-end">
          <b-button
            size="sm"
              variant="primary"
            class="mr-1"
            @click="record_edit_button()"
          >

            <span class="align-middle">Güncelle</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Güncelle Modal-->

    <!--    Sil Modal-->
    <b-modal
      id="delete_record_modal"
      ref="delete_record_modal"
      class="danger"
      centered

      no-close-on-backdrop
      content-class="shadow"
      title="Kayıt Sil"
      footer-class="d-none"
      size="md"
      @hide="close_delete_record_modal"
    >
      <div>
        <b-card-text class="text-left">
          <span class="text-danger">{{ selected_row_name }}</span><span>isimli kaydı silmek istediğinize emin misiniz?</span>
        </b-card-text>
        <b-row class="justify-content-end">
          <b-button
            size="sm"
              variant="primary"
            class="mr-1"
            @click="close_delete_record_modal()"
          >
            <feather-icon
              icon="XIcon"
              class="mr-1"
            />
            <span class="align-middle">İptal</span>
          </b-button>
          <b-button
            variant="danger"
size="sm"
            class="mr-1"
            @click="record_delete_button()"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-1"
            />
            <span class="align-middle">Sil</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Sil Modal-->

    <b-card>
      <div class="mb-1">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >

            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />

          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                size="sm"
                class="d-inline-block mr-1"
                :placeholder="$t('core.search')"
              />
              <b-button
                v-b-modal.new_record_modal
                size="sm"
                variant="primary"
              >
                <span class="text-nowrap">{{ $t('core.btn_new_add') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        responsive
        :items="table_data"
        :fields="tableColumns"
        primary-key="id"
        class="text-nowrap"
        show-empty
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :empty-text="$t('core.no_records_found')"
        small
        striped
      >
        <!-- Column: Actions -->
        <template #cell(currency)="data">
          <div class="text-nowrap">
            <b-badge variant="secondary">
              <span class="align-middle">{{data.item.currency.currency_symbol}} | {{data.item.currency.currency_name}}</span>
            </b-badge>
          </div>
        </template>

        <template #cell(id)="data">
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="rowUpdateModal(data.item.id, )">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Düzenle</span>
              </b-dropdown-item>
              <b-dropdown-item @click="rowDeleteModal(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Sil</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>

</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
  BModal,
  BCardHeader,
  BCardBody,
  BLink,
  BFormInput,
  BTable,
  BBadge,
  BCardText,
  BFormGroup,
  BDropdown,
  BFormCheckbox,
  BDropdownItem,
  BPagination, VBModal, BInputGroup, BInputGroupAppend, BFormTextarea,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import store from '@/store'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BModal,
    BCardHeader,
    BCardBody,
    BLink,
    BFormInput,
    BFormTextarea,
    BTable,
    BFormCheckbox,
    BBadge,
    BCardText,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      edit_qr_code_limit: null,
      edit_total_user_limit: null,
      edit_total_group_limit: null,
      edit_max_disk_quota: null,
      edit_custom_return_url: null,
      edit_custom_logo: null,
      edit_total_organization_limit: null,
      edit_biolink_status: null,
      qr_code_limit: null,
      total_user_limit: null,
      total_group_limit: null,
      max_disk_quota: null,
      custom_return_url: null,
      custom_return_url_options: [
        {title: "Evet", value: true},
        {title: "Hayır", value: false}
      ],
      custom_logo: null,
      custom_logo_options: [
        {title: "Evet", value: true},
        {title: "Hayır", value: false}
      ],
      total_organization_limit: 1,
      biolink_status: null,
      biolink_status_options:  [
        {title: "Evet", value: true},
        {title: "Hayır", value: false}
      ],
      selected_row_name: null,
      selected_row_id: null,
      perPageOptions: [25, 50, 100],
      searchQuery: null,
      sortBy: null,
      isSortDirDesc: null,
      roleOptions: [],
      table_data: null,
      tableColumns: [
        { key: 'priority_no', label: 'Sıra No', sortable: true },
        { key: 'title', label: 'Başlık', sortable: true },
        { key: 'sub_title', label: 'Alt Başlık', sortable: true },
        { key: 'plan_tag', label: 'Plan TAG', sortable: true },
        { key: 'monthly_price', label: 'Aylık Fiyat', sortable: true },
        { key: 'annual_price', label: 'Yıllık Fiyat', sortable: true },
        { key: 'currency', label: 'Para Birimi', sortable: true },
        { key: 'features', label: 'Özellikler', sortable: true },
        { key: 'id', label: '#' },
      ],
      currentPage: 1,
      totalRows: 0,
      perPage: 25,
      title: null,
      sub_title: null,
      monthly_price: null,
      annual_price: null,
      priority_no: null,
      features: null,
      currency: { title: 'Para Birimi', value: null },
      currency_options: null,
      plan_tag: { title: 'Plan TAG', value: null },
      plan_tag_options: store.getters.getPlanTags,
      edit_title: null,
      edit_sub_title: null,
      edit_monthly_price: null,
      edit_annual_price: null,
      edit_priority_no: null,
      edit_features: null,
      edit_currency: { title: 'Para Birimi', value: null },
      edit_plan_tag: { title: 'Plan TAG', value: null },
    }
  },
  computed: {
  },
  watch: {
    sortBy: { handler() { this.getTableData() } },
    isSortDirDesc: { handler() { this.getTableData() } },
    currentPage: {
      handler() {
        this.getTableData()
      },
    },
    searchQuery: {
      handler() {
        this.getTableData()
      },
    },
  },
  created() {
    this.getTableData()
    this.getCurrencyData()
  },
  setup() {},
  methods: {
    getCurrencyData() {
      useJwt.getCurrencyList({
        page_size: 5000,
      })
        .then(response => {
          const currency_list = []
          response.data.results.forEach(mm => {
            currency_list.push({
              title: `${mm.currency_name} - ${mm.currency_symbol}`,
              value: mm.id,
            })
            this.currency_options = currency_list
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    getTableData() {
      useJwt.getSubPlanList({
        // center_type: this.filter_center_type.value,
        plan: router.currentRoute.params.id,
        page: this.currentPage,
        page_size: this.perPage,
        search: this.searchQuery,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
      })
        .then(response => {
          this.table_data = response.data.results
          this.totalRows = response.data.count
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    rowUpdateModal(row_id) {
      useJwt.SubPlanDetail(row_id)
        .then(response => {
          this.edit_title = response.data.title
          this.edit_sub_title = response.data.sub_title
          this.edit_monthly_price = response.data.monthly_price
          this.edit_annual_price = response.data.annual_price
          this.edit_priority_no = response.data.priority_no
          this.edit_features = response.data.features
          this.edit_qr_code_limit = response.data.qr_code_limit
          this.edit_total_user_limit = response.data.total_user_limit
          this.edit_total_group_limit = response.data.total_group_limit
          this.edit_max_disk_quota = response.data.max_disk_quota
          this.edit_total_organization_limit = response.data.total_organization_limit

          this.custom_return_url_options.forEach(vvv => {
            if (vvv.value === response.data.custom_return_url) {
              this.edit_custom_return_url = vvv
            }
          })
          this.custom_logo_options.forEach(vvv => {
            if (vvv.value === response.data.custom_logo) {
              this.edit_custom_logo = vvv
            }
          })
          this.biolink_status_options.forEach(vvv => {
            if (vvv.value === response.data.biolink_status) {
              this.edit_biolink_status = vvv
            }
          })

          this.currency_options.forEach(vvv => {
            if (vvv.value === response.data.currency.id) {
              this.edit_currency = vvv
            }
          })
          this.plan_tag_options.forEach(vvv => {
            if (vvv.value === response.data.plan_tag) {
              this.edit_plan_tag = vvv
            }
          })
          this.selected_row_id = response.data.id
          this.$refs.update_record_modal.show()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    rowDeleteModal(row_id) {
      useJwt.SubPlanDetail(row_id)
        .then(response => {
          this.selected_row_name = response.data.title
          this.selected_row_id = response.data.id
          this.$refs.delete_record_modal.show()
        })
        .catch(error => {
          console.log(error)
        })
    },
    new_record_add_button() {
      const formData = new FormData()
      formData.append('plan', router.currentRoute.params.id)
      formData.append('title', this.title)
      formData.append('sub_title', this.sub_title)
      formData.append('annual_price', this.annual_price)
      formData.append('monthly_price', this.monthly_price)
      formData.append('currency', this.currency.value)
      formData.append('features', this.features)
      formData.append('priority_no', this.priority_no)
      formData.append('plan_tag', this.plan_tag.value)
      formData.append('qr_code_limit', this.qr_code_limit)
      formData.append('total_user_limit', this.total_user_limit)
      formData.append('total_group_limit', this.total_group_limit)
      formData.append('max_disk_quota', this.max_disk_quota)
      formData.append('total_organization_limit', this.total_organization_limit)
      formData.append('custom_return_url', this.custom_return_url.value)
      formData.append('custom_logo', this.custom_logo.value)
      formData.append('biolink_status', this.biolink_status.value)

      useJwt.createSubPlan(formData)
        .then(response => {
          this.getTableData()
          this.close_new_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Eklendi',
              icon: 'SaveIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.error'),
              icon: 'AlertIcon',
              variant: 'danger',
              text: error.response.data.detail,
            },
          })
          console.log(error.response)
        })
    },
    record_edit_button() {
      const formData = new FormData()
      formData.append('title', this.edit_title)
      formData.append('sub_title', this.edit_sub_title)
      formData.append('annual_price', this.edit_annual_price)
      formData.append('monthly_price', this.edit_monthly_price)
      formData.append('currency', this.edit_currency.value)
      formData.append('features', this.edit_features)
      formData.append('priority_no', this.edit_priority_no)
      formData.append('plan_tag', this.edit_plan_tag.value)
      formData.append('qr_code_limit', this.edit_qr_code_limit)
      formData.append('total_user_limit', this.edit_total_user_limit)
      formData.append('total_group_limit', this.edit_total_group_limit)
      formData.append('max_disk_quota', this.edit_max_disk_quota)
      formData.append('total_organization_limit', this.edit_total_organization_limit)
      formData.append('custom_return_url', this.edit_custom_return_url.value)
      formData.append('custom_logo', this.edit_custom_logo.value)
      formData.append('biolink_status', this.edit_biolink_status.value)
      useJwt.updateSubPlan(this.selected_row_id, formData)
        .then(response => {
          this.getTableData()
          this.close_edit_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Güncellendi',
              icon: 'CheckCircleIcon',
              variant: 'success',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    record_delete_button() {
      useJwt.SubPlanDelete(this.selected_row_id)
        .then(response => {
          this.getTableData()
          this.close_delete_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Silindi',
              icon: 'CheckCircleIcon',
              variant: 'danger',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    close_new_record_modal() {
      this.$refs.new_record_modal.hide()
      this.title = null
      this.sub_title = null
      this.monthly_price = null
      this.annual_price = null
      this.priority_no = null
      this.features = null
      this.qr_code_limit = null
      this.total_user_limit = null
      this.total_group_limit = null
      this.max_disk_quota = null
      this.custom_return_url = null
      this.custom_logo = null
      this.total_organization_limit = null
      this.biolink_status = null
      this.currency = { title: 'Para Birimi', value: null }
      this.plan_tag = { title: 'Plan TAG', value: null }
    },
    close_edit_record_modal() {
      this.$refs.update_record_modal.hide()
      this.selected_row_id = null
      this.edit_title = null
      this.edit_sub_title = null
      this.edit_monthly_price = null
      this.edit_annual_price = null
      this.edit_priority_no = null
      this.edit_features = null
      this.edit_qr_code_limit = null
      this.edit_total_user_limit = null
      this.edit_total_group_limit = null
      this.edit_max_disk_quota = null
      this.edit_custom_return_url = null
      this.edit_custom_logo = null
      this.edit_total_organization_limit = null
      this.edit_biolink_status = null
      this.edit_currency = { title: 'Para Birimi', value: null }
      this.edit_plan_tag = { title: 'Plan TAG', value: null }
    },
    close_delete_record_modal() {
      this.$refs.delete_record_modal.hide()
      this.selected_row_id = null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
</style>
